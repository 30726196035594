<template>
	<el-row class="edu">
		<el-aside>
			<div class="catalog"></div>
            <div class="title_control">
				<el-button type="plain" size="small" @click="editDoc"
					>编辑文档</el-button
				>
				<el-button type="plain" size="small" @click="reloadNavigation"
					>刷新目录</el-button
				>
			</div>
		</el-aside>
		<el-main class="content">
			<article v-html="content"></article>
		</el-main>

        <!-- 编辑 -->
		<editor
			ref="editor"
			@close="closeDialog"
			:show="showEditor"
			:docContent="content"
		></editor>
	</el-row>
</template>

<script>
import editor from "./editor";
// import "autocjs/dist/css/autoc.css";
// import Autocjs from "autocjs";
import "./autojs/src/css/autoc.css";
import Autocjs from "./autojs/src/js/autoc";
export default {
	name: "index",
	data() {
		return {
			content: "",
			showEditor: false,
			navigation: null,
		};
	},
	mounted() {
		this.$nextTick(() => {
			this.initData();
		});
	},
	methods: {
		getNavigator() {
			this.navigation = new Autocjs({
                position: "inside",
                isGenerateOutlineChapterCode: false,
                article: "article"
            });
			const ele = document.getElementsByClassName("outline-inside");
			const target = document.querySelector(".catalog");
			target.appendChild(ele[0]);
        },
		reloadNavigation() {
			this.navigation.reload({
				position: "inside",
                article: "article",
                isGenerateOutlineChapterCode: false,
			});
			const ele = document.getElementsByClassName("outline-inside");
			const target = document.querySelector(".catalog");
			target.appendChild(ele[0]);
		},
		async initData() {
			const result = await this.$http({
				url: "/emergency/guideline/queryFile",
			});
			this.content = result.detail;
			this.$nextTick(() => {
				if (this.navigation) {
					this.reloadNavigation();
				} else {
					this.getNavigator();
				}
			});
		},
		closeDialog() {
			this.showEditor = false;
			this.initData();
		},
		editDoc() {
			this.showEditor = true;
		},
		getEditHtml(content) {
			this.content = content;
		},
	},
	components: {
		editor,
	},
};
</script>


<style lang="scss">
.outline-inside{
    display: flex;
    flex-direction: column;
    overflow: hidden;
    height: 100%;
    .outline-inside-header{
        margin: 0;
    }
    .outline-inside-body{
        flex: 1;
        overflow: auto;
        max-height: 100%;
        margin: 0;
    }
}
</style>
<style lang="scss" scoped>
@import "~autocjs/dist/css/autoc.css";
.edu {
    position: relative;
    height: 100%;
    .el-aside{
        background-color:white;
        padding:10px;
        display: flex;
        flex-direction: column;
        position: absolute;
        width: 300px;
        left: 0;
        top: 0;
        bottom: 0;
        .title_control{
            height: 42px;
            display: flex;
            padding-top: 10px;
            justify-content: space-around;
        }
        .catalog{
             flex: 1;
             overflow: hidden;
        }
    }
    .el-main{
        position: absolute;
        left: 300px;
        right: 0;
        top: 0;
        bottom: 0;
        overflow-y: scroll;
        padding: 0;
        margin: 0 10px;
       article {
            font-size: 16px;
            padding: 3.5em;
            background-color: #ffffff;
            box-shadow: 0 0 15px #ddd;
            display: block;
       }
    }
}
</style>
