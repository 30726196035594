<template>
  <el-dialog
    title="编辑文档"
    :visible.sync="visible"
    width="70%"
    @open="openDialog"
    :before-close="handleClose"
  >
    <div ref="editorElem"></div>
    <div class="footer">
      <el-button type="primary" @click="confirm">确定</el-button>
      <el-button type="plain" @click="handleClose">取消</el-button>
    </div>
  </el-dialog>
</template>

<script>
import E from "wangeditor";
import _ from "loadsh";
export default {
  name: "editor",
  props: {
      //["show", "docContent"]
      show: Boolean,
      docContent: String
  },
  watch: {
    show: {
        handler() {
      this.visible = this.show;
    },
    immediate: true
    }
  },
  methods: {
    openDialog() {
      this.$nextTick(() => {
        const initialize = _.once(this.initEditor);
        initialize();
        this.editor.txt.html(this.docContent);
      });
    },
    initEditor() {
      this.editor = new E(this.$refs.editorElem);
      // 编辑器的事件，每次改变会获取其html内容
      this.editor.customConfig.onchange = html => {
        this.editorContent = html;
      };
      this.editor.customConfig.pasteIgnoreImg = true
      this.editor.customConfig.uploadImgShowBase64 = true;
      this.editor.customConfig.menus = this.customConfig;
      this.editor.create(); // 创建富文本实例
    },
    handleClose() {
      this.editorContent = "";
      this.$emit("close");
    },
    async confirm() {
      this.$http({
          url: "/emergency/guideline/addFile",
          data: {
              content: this.editorContent
          }}).then(() => {
              this.handleClose();
          });
    },
    getCatalogue() {
      return this.editor.getJSON()
    }
  },
  data() {
    return {
      visible: false,
      editor: null,
      editorContent: "",
      // editorFunc: _.once(this.initEditor),

      customConfig: [
        // 菜单配置
        "head", // 标题
        "bold", // 粗体
        "fontSize", // 字号
        "fontName", // 字体
        "italic", // 斜体
        "underline", // 下划线
        "strikeThrough", // 删除线
        "foreColor", // 文字颜色
        "backColor", // 背景颜色
        "link", // 插入链接
        "list", // 列表
        "justify", // 对齐方式
        "quote", // 引用
        "emoticon", // 表情
        "image", // 插入图片
        "table", // 表格
        "code", // 插入代码
        "undo", // 撤销
        "redo" // 重复
      ]
    };
  },
  components: {}
};
</script>

<style lang="scss" scoped>
.footer {
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  margin-top: 20px;
}
</style>
